<template>
  <div class="clue-edit">
    <Back :title="title"></Back>
    <main>
      <van-form @submit="onSubmit">
        <section class="form-item">
          <h1 class="form-item-title van-hairline--bottom">客户信息</h1>
          <!-- 客户名称 -->
          <van-field v-model="ruleForm.tenantName" name="tenantName" label="客户名称" placeholder="请输入客户名称"
            :rules="[required]" maxlength="15" show-word-limit clearable autofocus autocomplete="off" />
          <!-- 联系人 -->
          <van-field v-model="ruleForm.userName" name="userName" label="联系人" placeholder="请输入联系人" :rules="[required]"
            maxlength="15" show-word-limit clearable autocomplete="off" />
          <!-- 联系电话 -->
          <van-field v-model="ruleForm.mobile" name="mobile" label="联系电话" placeholder="请输入联系电话" :rules="[required]"
            maxlength="11" show-word-limit clearable type="number" autocomplete="off" />
          <!-- 招商经理 -->
          <van-field v-model="ruleForm.consultantName" name="consultantName" is-link readonly label="招商经理"
            placeholder="请选择招商经理" @click="consultantUserShow = true" :rules="[required]" />
          <!-- 来源渠道 -->
          <van-field v-model="ruleForm.channelTypeName" name="channelTypeName" label="来源渠道" placeholder="请选择来源渠道"
            @click="channelTypeShow = true" readonly clickable :rules="[required]" />
          <!-- 合伙人及二级渠道 -->
          <van-field v-model="ruleForm.partnerName" name="partnerName"
            :label="ruleForm.channelType == '4' ? '二级渠道' : '合伙人'"
            :placeholder="ruleForm.channelType == '4' ? '请选择二级渠道' : '请选择合伙人'" @click="partnerShow = true" readonly
            clickable v-if="ruleForm.channelType != 3 && ruleForm.channelType != 5" />
          <!-- 客户等级 -->
          <van-field v-model="ruleForm.turnoverOddsName" name="turnoverOddsName" label="客户等级" placeholder="请选择客户等级"
            @click="turnoverOddsShow = true" readonly clickable />
          <!-- 客户状态 -->
          <van-field v-model="ruleForm.clueStatusName" name="clueStatusName" label="客户状态" placeholder="请选择客户状态"
            @click="clueStatusShow = true" readonly clickable />
          <!-- 行业 -->
          <van-field v-model="ruleForm.industryName" name="industryName" label="行业" placeholder="请选择客户行业"
            @click="industryShow = true" readonly clickable />
        </section>
        <section class="form-item">
          <h1 class="form-item-title van-hairline--bottom">需求信息</h1>
          <!-- 意向园区 -->
          <van-field v-model="ruleForm.projectName" name="projectName" label="意向园区" placeholder="请选择客户意向园区"
            @click="projectShow = true" readonly clickable :rules="[required]" />
          <!-- 需求面积 -->
          <van-field v-model="ruleForm.requiredAreaRangeName" name="requiredAreaRangeName" label="需求面积"
            placeholder="请选择客户需求面积" @click="requiredAreaRangeShow = true" readonly clickable />
          <!-- 备注信息 -->
          <van-field v-model="ruleForm.remarkContent" rows="3" autosize label="备注信息" type="textarea" maxlength="100"
            placeholder="请输入备注信息" show-word-limit />

        </section>
        <div style="margin: 16px;">
          <van-button round block type="info" native-type="submit">保&nbsp;&nbsp;&nbsp;存</van-button>
        </div>
      </van-form>
    </main>
    <!-- 招商经理弹框 -->
    <van-popup v-model="consultantUserShow" round position="bottom">
      <van-cascader v-model="ruleForm.consultantUserId" title="请选择招商经理" :options="consultantUserList"
        @close="consultantUserShow = false" @finish="onFinishConsultantUserId" />
    </van-popup>
    <!-- 来源渠道弹框 -->
    <van-popup v-model="channelTypeShow" round position="bottom">
      <van-picker show-toolbar @cancel="channelTypeShow = false" :columns="channelList"
        @confirm="onFinishChannelTypeShow" />
    </van-popup>
    <!-- 合伙人及二级渠道 -->
    <van-popup v-model="partnerShow" round position="bottom">
      <van-picker show-toolbar @cancel="partnerShow = false" :columns="partnerList" @confirm="onFinishPartnerShow" />
    </van-popup>
    <!-- 客户等级 -->
    <van-popup v-model="turnoverOddsShow" round position="bottom">
      <van-picker show-toolbar @cancel="turnoverOddsShow = false" :columns="objToOpt(turnoverOddsObj)"
        @confirm="onFinishTurnoverOddsShow" />
    </van-popup>
    <!-- 客户状态 -->
    <van-popup v-model="clueStatusShow" round position="bottom">
      <van-picker show-toolbar @cancel="clueStatusShow = false" :columns="clueStatusOpt"
        @confirm="onFinishClueStatusShow" />
    </van-popup>
    <!-- 行业 -->
    <van-popup v-model="industryShow" round position="bottom">
      <van-picker show-toolbar @cancel="industryShow = false" :columns="industryList" @confirm="onFinishIndustryShow" />
    </van-popup>
    <!-- 意向园区 -->
    <van-popup v-model="projectShow" round position="bottom">
      <van-picker show-toolbar @cancel="projectShow = false" :columns="projectList" @confirm="onFinishProjectShow" />
    </van-popup>
    <!-- 需求面积 -->
    <van-popup v-model="requiredAreaRangeShow" round position="bottom">
      <van-picker show-toolbar @cancel="requiredAreaRangeShow = false" :columns="areaList"
        @confirm="onFinishRequiredAreaRangeShow" />
    </van-popup>

  </div>
</template>

<script>
import Back from "@/components/back";
import { required } from "@/utils/rules"
import {
  objToOpt,
  changeDate,
} from "@/utils/utils"
import {
  turnoverOddsObj,
  clueStatusObj
} from "@/db/clue"
import {
  getConsultantUserList,
  getChannelList,
  getPartnerList,
  getIndustryList,
  getProjectList,
  getAreaList,
  getClueUpdate,
  getClueInfo,
} from "@/api/clue/clue.js"
import { Toast } from 'vant';
export default {
  components: { Back },
  data() {
    return {
      objToOpt,
      required,
      turnoverOddsObj,
      clueStatusObj,
      clueStatusOpt: [
        { text: '潜在', value: 1 },
        { text: '看房', value: 2 },
        { text: '无效', value: 4 },
        { text: '签约', value: 3, disabled: true },
      ],
      ruleForm: {
        clueId: this.$route.query.clueId || "",//线索ID
        tenantName: '',//客户名称
        userName: '',//联系人
        mobile: '',//联系电话
        deptId: "",//部门id
        consultantUserId: '',//招商经理用ID
        consultantName: '',//招商经理名称
        channelType: 3,//来源渠道
        // channelTypeName: '',//来源渠道名称
        partnerName: '',//合伙人名
        partnerId: '',//合伙ID
        turnoverOdds: '',//客户等级
        turnoverOddsName: '',//客户等级
        clueStatus: '',//客户状态
        clueStatusName: '',//客户状态
        industryId: '',//客户行业
        industryName: '',//客户行业
        projectId: '',//意向园区
        projectName: '',//意向园区
        requiredAreaRange: '',//需求面积
        requiredAreaRangeName: '',//需求面积
        remarkContent: '',//备注信息
      },
      //招商经理
      consultantUserList: [],
      consultantUserShow: false,
      //来源渠道
      channelList: [],
      channelTypeShow: false,
      //合伙人及二级渠道
      partnerList: [],
      partnerShow: false,
      // 客户等级
      turnoverOddsShow: false,
      // 客户状态
      clueStatusShow: false,
      //行业
      industryList: [],
      industryShow: false,
      // 意向园区
      projectList: [],
      projectShow: false,
      // 需求面积
      areaList: [],
      requiredAreaRangeShow: false,
    }
  },
  computed: {
    title() {
      return this.ruleForm.clueId ? "编辑线索" : "新建线索";
    }
  },
  created() {
    // 编辑
    if (this.ruleForm.clueId) {
      this.getClueInfo(); // 【请求】线索详情
    }
    // 新建
    else {
      this.ruleForm.channelTypeName = '自主开发'
      this.ruleForm.turnoverOdds = 3
      this.ruleForm.turnoverOddsName = '低'
      this.ruleForm.clueStatus = 1
      this.ruleForm.clueStatusName = '潜在客户'

    }
    this.getConsultantUserList(); // 【请求】园区总监和客户经理列表
    this.getChannelList();// 【请求】来源渠道列表
    this.getIndustryList();// 【请求】行业列表
    this.getAreaList();// 【请求】需求面积
  },
  methods: {
    // 【请求】园区总监和客户经理列表
    getClueInfo() {
      let clueId = this.ruleForm.clueId;
      getClueInfo(clueId).then((res) => {
        if (res.success) {
          this.ruleForm = res.data;
          this.ruleForm.turnoverOddsName = turnoverOddsObj[this.ruleForm.turnoverOdds];
          this.ruleForm.clueStatusName = clueStatusObj[this.ruleForm.clueStatus];
          this.getPartnerList(); //合伙人列表
          this.getProjectList(); //意向园区
        }
      });
    },
    // 【请求】园区总监和客户经理列表
    getConsultantUserList() {
      getConsultantUserList().then((res) => {
        if (res.success) {
          let list = res.data;
          list = JSON.parse(JSON.stringify(list).replace(/deptId/g, "value").replace(/deptName/g, "text").replace(/empDtos/g, "children").replace(/empId/g, "value").replace(/empName/g, "text")) //修改对象属性名
          this.consultantUserList = list;
        }
      });
    },

    // 【请求】来源渠道列表
    getChannelList() {
      getChannelList().then((res) => {
        if (res.success) {
          let list = res.list;
          list = JSON.parse(JSON.stringify(list).replace(/codeValue/g, "value").replace(/codeLabel/g, "text")) //修改对象属性名
          this.channelList = list;
        }
      });
    },

    // 【请求】合伙人及二级渠道
    getPartnerList() {
      let channelType = this.ruleForm.channelType;
      getPartnerList(channelType).then((res) => {
        if (res.success) {
          let list = res.data;
          list = JSON.parse(JSON.stringify(list).replace(/partnerId/g, "value").replace(/partnerName/g, "text")) //修改对象属性名
          this.partnerList = list;
        }
      });
    },

    // 【请求】行业列表
    getIndustryList() {
      getIndustryList().then((res) => {
        if (res.success) {
          let list = res.list;
          list = JSON.parse(JSON.stringify(list).replace(/industryId/g, "value").replace(/industryName/g, "text")) //修改对象属性名
          this.industryList = list;
        }
      });
    },

    // 【请求】意向园区
    getProjectList() {
      let deptId = this.ruleForm.deptId;
      getProjectList(deptId).then((res) => {
        if (res.success) {
          let list = res.list;
          list = JSON.parse(JSON.stringify(list).replace(/projectId/g, "value").replace(/projectName/g, "text")) //修改对象属性名
          this.projectList = list;
        }
      });
    },

    // 【请求】需求面积
    getAreaList() {
      getAreaList().then((res) => {
        if (res.success) {
          let list = res.list;
          list = JSON.parse(JSON.stringify(list).replace(/codeValue/g, "value").replace(/codeLabel/g, "text")) //修改对象属性名
          this.areaList = list;
        }
      });
    },

    // 【监听】客户经理选择
    onFinishConsultantUserId({ selectedOptions }) {
      this.consultantUserShow = false;
      this.ruleForm.consultantName = selectedOptions.map((option) => option.text).join('/');
      this.ruleForm.deptId = selectedOptions[0].value;
      this.getProjectList(); //意向园区
    },

    // 【监听】来源渠道选择
    onFinishChannelTypeShow(value) {
      this.channelTypeShow = false;
      this.ruleForm.channelTypeName = value.text;
      this.ruleForm.channelType = value.value;
      this.getPartnerList();
    },

    // 【监听】合伙人及二级渠道选择
    onFinishPartnerShow(value) {
      this.partnerShow = false;
      this.ruleForm.partnerName = value.text;
      this.ruleForm.partnerId = value.value;
    },

    // 【监听】客户等级
    onFinishTurnoverOddsShow(value) {
      this.turnoverOddsShow = false;
      this.ruleForm.turnoverOddsName = value.text;
      this.ruleForm.turnoverOdds = value.value;
    },

    // 【监听】客户等级
    onFinishClueStatusShow(value) {
      this.clueStatusShow = false;
      this.ruleForm.clueStatusName = value.text;
      this.ruleForm.clueStatus = value.value;
    },

    // 【监听】客户行业
    onFinishIndustryShow(value) {
      this.industryShow = false;
      this.ruleForm.industryName = value.text;
      this.ruleForm.industryId = value.value;
    },

    // 【监听】意向园区
    onFinishProjectShow(value) {
      this.projectShow = false;
      this.ruleForm.projectName = value.text;
      this.ruleForm.projectId = value.value;
    },

    // 【监听】需求面积
    onFinishRequiredAreaRangeShow(value) {
      this.requiredAreaRangeShow = false;
      this.ruleForm.requiredAreaRangeName = value.text;
      this.ruleForm.requiredAreaRange = value.value;
    },

    // 【监听】提交
    onSubmit(values) {
      let data = this.ruleForm;
      getClueUpdate(data).then((res) => {
        if (res.success) {
          Toast(res.msg);
          let clueId = this.ruleForm.clueId
          if (clueId) {
            this.$router.push("/clue/clueInfo?clueId=" + clueId);
          } else {
            this.$router.push("/clue/clueList");
          }
        }
      });
    },

  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/theme';

.clue-edit {
  height: 100%;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
  overflow: auto;
}

.form-item {
  border-bottom: .15rem #f1f2f6 solid;
}

.form-item-title {
  padding: .1rem .15rem;
}
</style>