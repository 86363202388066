export const required = {
  required: true,
  message: '必填项不得为空'
}
// 整数
const intValidator = (val) => {
  return /^[0-9]*$/.test(val);
};

export const rules = {
  empId: [{
    required: true,
    trigger: 'blur'
  }],
  workDay: [{
    required: true,
    message: '请填写工期天数',
    trigger: 'blur'
  }, {
    validator: intValidator,
    message: '请填写整数',
    trigger: 'blur',
  }],
}